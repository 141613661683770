import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function OtpLogin(props) {

    const [show, setShow] = useState(true)

    const handleShow = () => {
        if (show) {
            setShow(!show)
        } else {
            setShow(!show)
        }


    }
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Login / Signup
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {show ? <>
                        <div className="otp-login">
                            <div className="mb-3">
                                <label htmlFor="MobileNumber" className="form-label">Mobile Number</label>
                                <input type="tel" className="form-control" id="MobileNumber" aria-describedby="emailHelp" />
                                {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                            </div>
                            <button type="button" onClick={handleShow} className="btn btn-primary">GET OTP</button>
                        </div>
                    </> : <>
                        <div className="otp-login verify-otp">
                            <h2>Verify OTP</h2>
                            <p>Verify Mobile Number 6206958466 <Link to="#" onClick={() => handleShow()}>Change</Link></p>
                            <div className="mb-3">
                                <label htmlFor="MobileNumber" className="form-label">Enter OTP</label>
                                <input type="tel" className="form-control" id="MobileNumber" aria-describedby="emailHelp" />
                                {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                            </div>
                            <button type="button" className="btn btn-primary">Verify</button>
                        </div>
                    </>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default OtpLogin