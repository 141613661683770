import category1 from "../assets/img/admclick/img-1.webp";
import category2 from "../assets/img/admclick/img-2.webp";
import category3 from "../assets/img/admclick/img-3.webp";
import category4 from "../assets/img/admclick/img-4.webp";
import category5 from "../assets/img/admclick/img-5.webp";
import category6 from "../assets/img/admclick/img-6.webp";
import category7 from "../assets/img/admclick/img-7.webp";
import category8 from "../assets/img/admclick/img-8.webp";
export const featuredDB = [
  {
    _id: 1,
    name: "Electronics",
    price: "1,450.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category1,
    type: "Standard",
    brand: "RK International",
    category: "Electronics",
    stock: "Yes",
    unit: "Piece (Piece)"
  },
  {
    _id: 2,
    name: "TVs / Video",
    price: "1,150.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category2,
    type: "Standard",
    brand: "RK International",
    category: "TVs / Video",
    stock: "No",
    unit: "Piece (Piece)"
  },
  {
    _id: 3,
    name: "Video games",
    price: "600.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category3,
    type: "Standard",
    brand: "RK International",
    category: "Video games",
    stock: "Yes",
    unit: "Piece (Piece)"
  },
  {
    _id: 4,
    name: "Cameras & Photo",
    price: "600.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category4,
    type: "Standard",
    brand: "RK International",
    category: "Cameras & Photo",
    stock: "No",
    unit: "Piece (Piece)"
  },
  {
    _id: 5,
    name: "Cell Phones",
    price: "500.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category5,
    type: "Standard",
    brand: "RK International",
    category: "Cell Phones",
    stock: "Yes",
    unit: "Piece (Piece)"
  },
  {
    _id: 6,
    name: "Sports & Outdoors",
    price: "500.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category6,
    type: "Standard",
    brand: "RK International",
    category: "Sports & Outdoors",
    stock: "No",
    unit: "Piece (Piece)"
  },
  {
    _id: 6,
    name: "Apparel",
    price: "500.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category7,
    type: "Standard",
    brand: "RK International",
    category: "Sports & Outdoors",
    stock: "No",
    unit: "Piece (Piece)"
  },
  {
    _id: 6,
    name: "Car Electronics",
    price: "500.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category8,
    type: "Standard",
    brand: "RK International",
    category: "Car Electronics",
    stock: "No",
    unit: "Piece (Piece)"
  },
];
