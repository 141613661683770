import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter, BsInstagram, BsWhatsapp } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { MdCall } from "react-icons/md";
import { HiMail } from "react-icons/hi";
import "./Footer.css";
import axios from "axios";
import footerImg from '../../assets/img/logo/logo-2.jpeg'
function Footer() {

  const [state, setState] = useState({
    about_company: "",
    office_address: "",
    location: "",
    phoneNo: "",
    email: "",
  })


  const getData = async () => {
    const res = await axios.get(`https://onlineparttimejobs.in/api/adminWeb_footer/get`)
    setState({
      about_company: res.data.about_company,
      office_address: res.data.office_address,
      location: res.data.contact_info.location,
      phoneNo: res.data.contact_info.phoneNo,
      email: res.data.contact_info.email,
    })
    window.localStorage.setItem('callNum', res.data.contact_info.phoneNo)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {/* Start Footer Section */}
      <section className="footer-section footer-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="aboutCompanyText">
                <h3>About Company</h3>
                <p>
                  {/* A site that sells products online. Allows users to create a purchase order, choose a payment method and deliver the order on the Internet. Having chosen the necessary goods or services, the user usually has the opportunity to select a method of payment and delivery on the site right away. */}
                  {state?.about_company}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-imag mb-2">
                  <img src={footerImg} alt="" />
                </div>
                <div className="footer-heading">
                  <h3>Office Address</h3>
                </div>

                <p>
                  {/* 98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025 */}
                  {state?.office_address}
                </p>
                <ul className="footer-social">
                  <li>
                    <a href="https://mmslfashions.in/" className="facebook">
                      <FaFacebookF />
                    </a>
                  </li>

                  <li>
                    <a href="https://mmslfashions.in/" className="twitter">
                      <BsTwitter />
                    </a>
                  </li>

                  <li>
                    <a href="https://mmslfashions.in/" className="instagram">
                      <BsInstagram />
                    </a>
                  </li>

                  <li>
                    <a href="https://mmslfashions.in/" className="whatsapp">
                      <BsWhatsapp />
                    </a>
                  </li>


                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>Our Support</h3>
                </div>

                <ul className="footer-quick-links">
                  <li>
                    <Link to="/about"> About</Link>
                  </li>
                  <li>
                    <Link to="/contact"> Contact</Link>
                  </li>
                  <li>
                    <Link to="/blog"> Blog</Link>
                  </li>
                  <li>
                    <Link to="/photo-gallery"> Photo Gallery</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/testimonial">Testimonial</Link>
                  </li>
                  <li>
                    <Link to="/view-all-brands">View-All-Brands</Link>
                  </li>
                  <li>
                    <Link to="/pickup_point_store">Pickup Point Store List</Link>
                  </li>
                  <li>
                    <Link to="/affiliate_register">Affiliate Register</Link>
                  </li>
                  <li>
                    <Link to="/our_people">Our Peoples</Link>
                  </li>

                  <li>
                    <Link to="/all_categories">All Categories</Link>
                  </li>

                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>Contact info</h3>
                </div>

                <div className="footer-info-contact">
                  <div className="topIcon">
                    <GoLocation />
                    <span>
                      <h3>Location</h3>
                      {state?.location}
                      {/* 98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025 */}
                    </span>
                  </div>
                </div>

                <div className="footer-info-contact">
                  <div className="topIcon">
                    <MdCall />
                    <span>
                      <h3>Call Us</h3>
                      <a href={`tel:${state?.phoneNo}`}> {state?.phoneNo}</a>
                    </span>
                  </div>
                </div>

                <div className="footer-info-contact">
                  <div className="topIcon">
                    <HiMail />
                    <span>
                      <h3>Email Us</h3>
                      <a href={`mailto:${state?.email}`}>
                        <span
                          className="__cf_email__"
                        >
                          {state?.email}
                        </span>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>Newsletter</h3>
                </div>
                <p>
                  Sign up & get shopping vouchers & stay updated about latest styles and exclusive promotions
                </p>

                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="single-footer-widget">
                    <h5 style={{ color: "white" }}>Seller</h5>
                    <ul className="footer-quick-links sellerLists">
                      <li>
                        <Link to="/allSeller">Seller List</Link>
                      </li>
                      <li>
                        <Link to="/seller/login">Login</Link>
                      </li>
                      <li>
                        <Link to="/seller/sign-Up">Sign Up</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <form className="newsletter-form">
                  <input
                    type="email"
                    className="input-newsletter"
                    placeholder="Enter your email"
                    name="EMAIL"
                    required
                    autoComplete="off"
                  />
                  <button type="submit">
                    Subscribe Now
                    <i className="flaticon-plus"></i>
                  </button>
                  <div id="validator-newsletter" className="form-result"></div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="container">
            <div className="copyright-area-content">
              <div className="copyright-left">
                <p>
                  Copyright @
                  <script
                    data-cfasync="false"
                    src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
                  ></script>
                  <script>document.write(new Date().getFullYear())</script>{" "}
                  All Rights Reserved.
                </p>
              </div>

              <div className="copyright-right">
                <ul>
                  <li>
                    <Link to="/terms-of-use">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Footer Section */}
    </>
  );
}

export default Footer;
