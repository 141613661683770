import React, { useState } from "react";
import { Link } from "react-router-dom";
import ProductItem from "./product/ProductItem";
import "./Products.css";
import QuiekViewModal from "../../../pages/QueikViewModal/QuiekViewModal";

function Products({ productData }) {
  const [show, setShow] = useState(false);

  const [modelDataId, setModelDataId] = useState(null)
  const setProduct_id = (id) => {
    setModelDataId(id)
  }

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setModelDataId(id)
    setShow(true)
  };
  return (
    <>
      <section className="productsSection p-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="fishermanHeader d-flex justify-content-between align-items-end mb-4">
                <div className="fisherman-content">
                  <span>Our Products</span>
                  <h3>Featured Products</h3>
                </div>
                <div className="fisherman-btn">
                  <Link to="/products" className="optional-btn">
                    View More
                  </Link>
                </div>
              </div>
            </div>
            <ProductItem productData={productData} handleShow={handleShow} setProduct_id={setProduct_id} />
          </div>
        </div>
        {modelDataId && (
          <QuiekViewModal modelDataId={modelDataId} show={show} onHide={handleClose} size="xl"
            centered />
        )}
      </section>
    </>
  );
}

export default Products;
